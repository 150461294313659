import React, { FC } from 'react';
import cx from 'classnames';
import Img from 'gatsby-image';
import withLink from './withLink';
import PlainImage from './PlainImage';
import { IImage } from './models';
import './Image.scss';

const Image: FC<IImage> = ({
  className,
  alt,
  localImage,
  sizes,
  critical,
  performanceMode = false,
  lazyLoad,
}) => {
  const classNames = cx('dt-image', className);

  if (!localImage || !localImage.childImageSharp) {
    return null;
  }
  const fluid = { ...localImage.childImageSharp.fluid };

  if (sizes) {
    fluid.sizes = sizes;
  }

  return (
    <div className={classNames}>
      {performanceMode ? (
        <PlainImage
          className={className}
          src={localImage.childImageSharp.fluid.src}
          alt={alt}
          srcSetWebp={localImage.childImageSharp.fluid.srcSetWebp}
          srcSet={localImage.childImageSharp.fluid.srcSet}
          isLocalImage
        />
      ) : (
        <Img
          loading={lazyLoad ? 'lazy' : 'eager'}
          className="dt-image"
          fluid={fluid}
          {...{ alt, critical }}
        />
      )}
    </div>
  );
};

Image.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  lazyLoad: true,
};

export default withLink(Image);
