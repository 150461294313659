import React, { FC } from 'react';

import classNames from 'classnames';
import { IPlainImage } from './models';
import './PlainImage.scss';

const PlainImage: FC<IPlainImage> = ({
  className,
  srcSetWebp,
  srcSet,
  sizes,
  src,
  alt,
  isLocalImage,
  lazyLoad,
}) => {
  const classes = classNames(className, 'dt-plain-image', {
    'dt-plain-image--umbraco': !isLocalImage,
    'gatsby-image-wrapper': isLocalImage,
  });

  return (
    <div className={classes}>
      <picture>
        <source type="image/webp" srcSet={srcSetWebp || srcSet} sizes={sizes} />
        <img
          className="dt-plain-image__img"
          loading={lazyLoad ? 'lazy' : undefined}
          sizes={sizes}
          srcSet={srcSet}
          src={src}
          alt={alt}
        />
      </picture>
    </div>
  );
};

export default PlainImage;
