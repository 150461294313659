import React from 'react';
import Link from 'gatsby-link';
import { IImage } from './models';

const withLink = (Component: React.ComponentType<IImage>) => (props: IImage) =>
  props.link ? (
    <Link className="dt-image__link" to={props.link}>
      <Component {...props} />
    </Link>
  ) : (
    <Component {...props} />
  );

export default withLink;
